import {fixPrice} from "./utils";
import apps, {SaleMode} from "./apps";
import {OrderStatus} from "./order_model";

/** shop **/
export interface ShopTblData {
    id: number,
    shopid: string,
    name: string,
    descr: string
    status: string,
    address: string,

    tblNo: string,
    qrType: string,

    happy1: boolean,
    happy1Descr: string,
    happy1St: Date,
    happy1End: Date,
    happy2: boolean,
    happy2Descr: string,
    happy2St: Date,
    happy2End: Date,
    openingTime: Date,
    closingTime: Date,
    monOpeningTime: Date,
    monClosingTime: Date,
    tueOpeningTime: Date,
    tueClosingTime: Date,
    wedOpeningTime: Date,
    wedClosingTime: Date,
    thuOpeningTime: Date,
    thuClosingTime: Date,
    friOpeningTime: Date,
    friClosingTime: Date,
    satOpeningTime: Date,
    satClosingTime: Date,
    sunOpeningTime: Date,
    sunClosingTime: Date,
    signature: string,
    shopNotice: boolean,
    shopNoticeDescr: string,
    qrPaymentMethod: number,
    paymentRefId: string,
    defaultPaymentType: string,
    cnpSurchargeRate: number,
    cnpFixedTransactionFee: number,
}

/** user **/
export interface UserData {
    id: number,
    uuid: string,
    email: string,
    mobile: string,
    status: UserStatus,
}

export enum UserStatus {
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    HOLD = "HOLD",
    INACTIVE = "INACTIVE",
    QROFF = "QROFF",
}

/** Menu with Cate **/
export class CateMenuListData {
    cateId: number;
    cateName: string;
    menus: MenuListData[];
}

export class MenuListData {
    id: number;
    menuid: number;
    name: string;
    descr: string;
    price: number;
    priceSp: number;
    special: boolean;
    soldOut: boolean;
    imageVer: number;
    vegetarian: boolean;
    gluetenFree: boolean;
    spicy: boolean;
    newItem: boolean;
    priceHappy1: number;
    priceHappy2: number;
    happy1Only: boolean;
    happy2Only: boolean;
    optionIds: number[]; // tabletious option.id
}

/** Option **/
export class OptionListData {
    name: string;
    optional: boolean;
    multiple: boolean;
    min: number;
    max: number;
    descr: string;
    items: OptionItemData[];
    maxPrice: number;
    optionId: number; // pikashop option.id
    optionid: number; // tabletious option.id. (option tag만들 때 사용)
}

export class OptionItemData {
    name: string;
    price: number;
    idx: number;
    optionItemId: number;   // pikashop option.id
    optionitemid: number;   // tabletious option.id. (option tag만들 때 사용)
}

/** CateMenu and Option **/
export class CateMenuAndOptionData {
    cateMenus: CateMenuListData[];
    options: OptionListData[];
}

/**
 * options map을 tag으로 변환
 * option사이 구분은 ,로 option과 optionItem 구분은 /로, optionItem사이 구분은 |로 한다
 * optionId1/optionItemId1|optionItemId2,optionId2/optionItemId1
 * **/
export function tagOptions(options: Map<OptionListData, OptionItemData[]>): string {
    const sorted: [OptionListData, OptionItemData[]][] =
        Array.from(options.entries())
            .sort(([o], [o2]) => o.optionid - o2.optionid)
            .map(([o, oi]) =>
                [o, oi.sort((a, b) => a.optionitemid - b.optionitemid)]);

    return sorted.map(([o, oi]) =>
        o.optionid + "|" + oi.map(i => i.optionitemid).join("/")).join(",");
}

/**
 * Cart
 * recoil state로 사용할 것이므로 상태를 직접 바꾸지 말고 새 오브젝트를 생성하면서 변경해야 한다
 * 안 그러면 recoil에서 사용할 때 preventObject 어쩌고 에러남
 **/
export class Cart {
    count: number = 0;
    items: CartItem[] = [];
    shop: ShopTblData;
    saleMode: SaleMode;

    constructor(saleMode: SaleMode, shop: ShopTblData, count: number = 0, items: CartItem[] = []) {
        this.shop = shop;
        this.count = count;
        this.items = items;
        this.saleMode = saleMode;
    }

    public updateShop(shop: ShopTblData) {
        return new Cart(this.saleMode, shop, this.count, this.items);
    }

    public updateSaleMode(saleMode: SaleMode) {
        return new Cart(saleMode, this.shop, this.count, this.items);
    }

    /**
     * 추가는 1개씩만. 기존 같은 아이템이 있으면 1씩 증가한다. 이후에 추가시 qty를 받을 수 있도록 qty 파라미터를 받아둔다.
     */
    public addItemWithShop(saleMode: SaleMode, menu: MenuListData, options: Map<OptionListData, OptionItemData[]>, qty: number, shop: ShopTblData): Cart {
        const cart = (this.saleMode == SaleMode.UNKNOWN) ? this.updateSaleMode(saleMode) : this;
        return (this.shop != null) ? cart.addItem(menu, options, qty) : cart.updateShop(shop).addItem(menu, options, qty);
    }

    /**
     * 수정은 기존 아이템의 갯수만 업데이트한다. 만약 옵션을 변경한다면 다른 메뉴+옵션이라 추가로 처리된다.
     */
    public updateItemWithShop(menu: MenuListData, options: Map<OptionListData, OptionItemData[]>, qty: number, shop: ShopTblData): Cart {
        return (this.shop != null) ? this.updateItem(menu, options, qty) : this.updateShop(shop).updateItem(menu, options, qty);
    }

    /**
     * 기존 item이 있으면 제거한 뒤에 add하는 방식으로 update한다.
     */
    public updateItem(menu: MenuListData, options: Map<OptionListData, OptionItemData[]>, qty: number): Cart {
        const existing = this.items.find(item => item.isSame(menu, options));
        if (existing) {
            return this.removeItem(existing.tag()).addItem(menu, options, qty)
        } else {
            return this.addItem(menu, options, qty);
        }
    }

    public addItem(menu: MenuListData, options: Map<OptionListData, OptionItemData[]>, qty: number): Cart {
        const existing = this.items.find(item => item.isSame(menu, options));
        if (existing) {
            const newItems = this.items.map((i: CartItem) => {
                if (i.isSame(menu, options)) return new CartItem(this.saleMode, i.menu, options, i.qty + qty);
                else return i;
            });
            return new Cart(this.saleMode, this.shop, this.count, newItems);
        } else {
            const newItems = [...this.items];
            newItems.push(new CartItem(this.saleMode, menu, options, qty));
            return new Cart(this.saleMode, this.shop, this.count + 1, newItems);
        }
    }

    public changeQty(menuId: number, options: Map<OptionListData, OptionItemData[]>, qty: number) {
        const existing = this.items.find(item => item.isSame2(menuId, options));
        if (existing) existing.qty = qty;
        return new Cart(this.saleMode, this.shop, this.count, this.items);
    }

    public removeItem(menuTag: string): Cart {
        let removed = 0;
        if (this.items.find(item => item.tag() == menuTag)) removed = 1;
        const newItems = this.items.filter(item => item.tag() != menuTag);
        return new Cart(this.saleMode, this.shop, this.count - removed, newItems);
    }

    public total() {
        return fixPrice(
            this.items.reduce((prev, cur) => {
                return prev + cur.itemPrice * cur.qty;
            }, 0));
    }
}

/**
 * cart.items를 FlatList에서 사용하기 편한 오브젝트로 재구성
 */
export const mapToItems = (items: CartItem[]) => {
    return items.map(i => {
        return {
            key: i.tag(),
            name: i.menu.name,
            options: i.options,
            total: i.itemPrice,
            qty: i.qty,
            optionsDescr: () => {
                const ois: OptionItemData[] = [];
                Array.from(i.options.entries()).forEach(([o, oi]) => {
                    ois.push(...oi);
                });
                return ois.map(oi => oi.name).join(", ");
            },
        };
    });
};

export enum OrderType {
    TAKEAWAY = "TAKEAWAY",
    TABLE_QR = "TABLE_QR",
    TABLETIOUS_QR = "TABLETIOUS_QR",
    PAY_AT_COUNTER = "PAY_AT_COUNTER",
}

/**
 * 현재 준비중인 주문의 기본 정보
 * 주문이 시작되면(Add to order) YourOrder도 준비되야 함
 */
export class YourOrder {
    shopid: string;
    type: OrderType;
    qrType: string;    // Pikashop QR Type (3자리). OrderType을 더 세분화한 코드.
    tableNo: string;    // Table QR 또는 Tabletious QR인 경우만. Takeaway인 경우에도 tableNo를 사용할 수 있다.
    txId: number;      // Tabletious QR인 경우만
    payMethod: PayMethod;

    constructor(shopid: string = "", qrType: string, tableNo: string = "", txId: number = 0,
                payMethod: PayMethod = PayMethod.NOT_SELECTED) {
        this.shopid = shopid;
        this.type = this.findOrderType(qrType);
        this.qrType = qrType;
        this.tableNo = tableNo;
        this.txId = txId;
        this.payMethod = payMethod;
    }

    private findOrderType(qrType: string) {
        switch (qrType) {
            case "TAC": // takeaway with pay at counter
            case "TAP": // takeaway with fullpay when placing order
            case "TAA": // takeaway with combine all paymethods
                return OrderType.TAKEAWAY;
            case "TBC": // table-qr with pay at counter
            case "TBP": // table-qr with fullpay when placing order
                return OrderType.TABLE_QR;
            case "TQC": // tabletious-qr with pay at counter
                return OrderType.TABLETIOUS_QR;
            default:
                throw Error("Invalid QR Type: " + qrType);
        }
    }

    public getOrderInfo(): String {
        return (this.type == OrderType.TAKEAWAY) ? "TAKEAWAY" : `Table ${this.tableNo}`;
    }

    public updatePayMethod(payMethod: PayMethod): YourOrder {
        return new YourOrder(this.shopid, this.qrType, this.tableNo, this.txId, payMethod);
    }

    public getTable() {
        switch (this.type) {
            case OrderType.TAKEAWAY:
                return "TAKEAWAY";
            default:
                return `Table ${this.tableNo}`;
        }
    }

    getOrderInfoLong() {
        return (this.type == OrderType.TAKEAWAY) ? `TAKEAWAY (${this.tableNo})` : `DINING AT TABLE ${this.tableNo}`;
    }
}

export enum PayMethod {
    NOT_SELECTED = "NOT_SELECTED",
    CREDIT_CARD = "CREDIT_CARD",
    PAYPAL = "PAYPAL",
    STRIPE = "STRIPE",
    APPLE_PAY = "APPLE_PAY",
    PAY_AT_COUNTER = "PAY_AT_COUNTER",
}

export class CartItem {
    menu: MenuListData;
    options: Map<OptionListData, OptionItemData[]>;
    qty: number;
    itemPrice: number;  // menu.price + sum(selected_option_item.price)
    /**
     * tabletious로 보낼 SaleMode로 NORMAL, SPECIAL, HAPPY1, HAPPY2 중의 하나
     * apps.saleMode는 happyhour를 확인하기 위한 것이고, 이건 SPECIAL 적용여부가 추가된다.
     **/
    saleMode: SaleMode;

    constructor(saleMode: SaleMode, menu: MenuListData, options: Map<OptionListData, OptionItemData[]>, qty: number = 1) {
        this.menu = menu;
        this.options = options;
        this.qty = qty;

        let itemPrice = apps.getPrice(saleMode, menu);
        options.forEach((optionItems, option) =>
            itemPrice += optionItems.reduce((acc, cur) => acc + cur.price, 0)
        );
        this.itemPrice = itemPrice;

        if (saleMode == SaleMode.NORMAL) {
            this.saleMode = menu.special ? SaleMode.SPECIAL : SaleMode.NORMAL;
        } else {
            this.saleMode = saleMode;
        }
    }

    tag(): string {
        return this.menu.id + "^" + tagOptions(this.options);
    }

    increaseQty() {
        console.log(this.qty);
        this.qty++;
        console.log(this.qty);
    }

    isSame(other: MenuListData, otherOptions: Map<OptionListData, OptionItemData[]>) {
        return this.menu.id == other.id &&
            tagOptions(this.options) == tagOptions(otherOptions);
    }

    isSame2(otherMenuId: number, otherOptions: Map<OptionListData, OptionItemData[]>) {
        return this.menu.id == otherMenuId &&
            tagOptions(this.options) == tagOptions(otherOptions);
    }
}

export class Receipt {
    authorization: string;
    resultId: string;
    cardNumber: string;
    cardHolder: string;
    cardExpiry: string;
    cardToken: string;
    cardType: string;
    cardCategory: string;
    cardSubcategory: string;
    amount: number;
    decimalAmount: number;
    successful: boolean;
    message: string;
    reference: string;
    currency: string;
    transactionId: string;
    settlementDate: string;
    transactionDate: string;
    responseCode: string;
    captured: boolean;
    capturedAmount: number;
    rrn: string;
    cvvMatch: string;
    originalAmount: string;
    surchargeAmount: string;
    total: string;
    surchargeAmountDescription: string;

    authorizationTrackingId?: string;
    cardSequenceNumber?: string;
    scaExemption?: string;
    leastCostRouted?: string;
    originalTransactionReference?: string;
    serviceId?: string;
    trxSrc?: string;
    success: boolean;
    meaning: string;
    nextSteps?: string;

    shopId: number;
    orderId: number;

    constructor(
        authorization: string,
        resultId: string,
        cardNumber: string,
        cardHolder: string,
        cardExpiry: string,
        cardToken: string,
        cardType: string,
        cardCategory: string,
        cardSubcategory: string,
        amount: number,
        decimalAmount: number,
        successful: boolean,
        message: string,
        reference: string,
        currency: string,
        transactionId: string,
        settlementDate: string,
        transactionDate: string,
        responseCode: string,
        captured: boolean,
        capturedAmount: number,
        rrn: string,
        cvvMatch: string,
        originalAmount: string,
        surchargeAmount: string,
        total: string,
        surchargeAmountDescription: string,
        authorizationTrackingId?: string,
        cardSequenceNumber?: string,
        scaExemption?: string,
        leastCostRouted?: string,
        originalTransactionReference?: string,
        serviceId?: string,
        trxSrc?: string,
        success: boolean,
        meaning: string,
        nextSteps?: string,
        shopId: number,
        orderId: number
    ) {
        this.authorization = authorization;
        this.resultId = resultId;
        this.cardNumber = cardNumber;
        this.cardHolder = cardHolder;
        this.cardExpiry = cardExpiry;
        this.cardToken = cardToken;
        this.cardType = cardType;
        this.cardCategory = cardCategory;
        this.cardSubcategory = cardSubcategory;
        this.amount = amount;
        this.decimalAmount = decimalAmount;
        this.successful = successful;
        this.message = message;
        this.reference = reference;
        this.currency = currency;
        this.transactionId = transactionId;
        this.settlementDate = settlementDate;
        this.transactionDate = transactionDate;
        this.responseCode = responseCode;
        this.captured = captured;
        this.capturedAmount = capturedAmount;
        this.rrn = rrn;
        this.cvvMatch = cvvMatch;
        this.originalAmount = originalAmount;
        this.surchargeAmount = surchargeAmount;
        this.total = total;
        this.surchargeAmountDescription = surchargeAmountDescription;
        this.authorizationTrackingId = authorizationTrackingId;
        this.cardSequenceNumber = cardSequenceNumber;
        this.scaExemption = scaExemption;
        this.leastCostRouted = leastCostRouted;
        this.originalTransactionReference = originalTransactionReference;
        this.serviceId = serviceId;
        this.trxSrc = trxSrc;
        this.success = success;
        this.meaning = meaning;
        this.nextSteps = nextSteps;
        this.shopId = shopId;
        this.orderId = orderId;

    }
}

export class ReceiptRes {
    receiptId: number;
    receiptStatus: boolean;

    constructor(receiptId: number, receiptStatus: boolean) {
        this.receiptId = receiptId;
        this.receiptStatus = receiptStatus;
    }
}