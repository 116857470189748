import axios, {AxiosInstance} from "axios";
import {CateMenuAndOptionData, CateMenuListData, ShopTblData, UserData, Receipt, ReceiptRes} from "./model";
import {Order, OrderPlaceRes, OrderStatusRes} from "./order_model";
import apps from "./apps";

let axiosInst: AxiosInstance;

export async function getShop(shopId: string, tableNo): Promise<ShopTblData>  {
  return getAxiosInst().get(`/shop/${shopId}/tbl/${tableNo}`).then(res => res.data)
}

export async function getCateMenuList(shopId: number): Promise<CateMenuListData[]> {
  return getAxiosInst().get(`/menu/shop/${shopId}`).then(res => res.data)
}

export async function getCateMenuAndOptionList(shopId: number, tblNo: String): Promise<CateMenuAndOptionData> {
  return getAxiosInst().get(`/menu/shop/${shopId}/tbl/${tblNo}/withoption`).then(res => res.data)
}

export async function placeOrder(order: Order): Promise<OrderPlaceRes> {

  return getAxiosInst().post("/order/place", order).then(res => res.data)
}

export function getOrderStatus(orderId: number): Promise<OrderStatusRes> {
  return getAxiosInst().get(`/order/status/${orderId}`).then(res => {
    const data: OrderStatusRes = res.data;

    console.log(data);

    if (data.orderStatus == "FAILED" || data.orderStatus == "CONFIRMED") {
        return data;
    }

    throw new Error("RETRY");
  })
}

export async function findUser(uuid: string): Promise<UserData> {
  return getAxiosInst().post(`/user/uuid/${uuid}`).then(res => {
    const data: UserData = res.data;

    console.log(data);

    if (data.status != "ACTIVE") {
      throw new Error("Invalid User status " + data.status);
    }

    return data;
  })
}

export async function saveReceipt(receipt: Receipt): Promise<ReceiptRes> {

  return getAxiosInst().post("/receipt/create", receipt).then(res => res.data)
}


export async function findReceiptByOrderId(orderId: number): Promise<Receipt> {

  return getAxiosInst().get(`/receipt/findByOrderId/${orderId}`).then(res => res.data)
}


function getAxiosInst(host?: String) {
  if (axiosInst == null) {

    if (__DEV__) {
      host = `http://192.168.0.${apps.ipLast}:8090`;
    }
    else {
      if (apps.profile == "prod") {
        //host = "https://api.pikashop.com.au";
        host = "https://api.medianara.com.au";
      }
      else {
        host = "https://dev-api.pikashop.com.au";
      }
    }
    console.log(`Host: ${host}`);

    axiosInst = axios.create({
      baseURL: `${host}/api`,
      timeout: 30000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      }
    });
  }
  return axiosInst;
}
